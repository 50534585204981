.App {
  text-align: center;
  font-family: "RuneScape UF", sans-serif;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: fade-in 2s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title {
  font-size: 5.5rem;
  color: #fff;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
}

.tableContainer {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #333;
  padding: 10px;
}

.navbar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.navbar-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 8px 16px;
  transition: background-color 0.3s;
}

.navbar-link:hover {
  background-color: #555;
  border-radius: 4px;
}