/* HomePage.css */
.homepage {
    padding: 20px;
    background-color: #f4f4f4;
    min-height: 100vh;
}

.homepage-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.homepage-description {
    font-size: 18px;
    color: #555;
    margin-bottom: 40px;
}

.homepage-grid {
    display: flex;
    gap: 20px;
}

.homepage-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    flex: 1;
}

.homepage-card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.homepage-card p {
    color: #777;
}