.resume-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.resume-header h1 {
  margin-bottom: 0;
}

.resume-header p {
  margin-top: 5px;
}

.skills ul,
.experience ul {
  list-style-type: none;
  padding-left: 0;
}

.experience article {
  margin-bottom: 20px;
}

.resume-footer {
  text-align: center;
  margin-top: 40px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  .resume-container {
    width: 100%;
  }
}
/* Skills display inline with separators, and experience contributions with bullet points */
.skills ul,
.experience ul,
.skills-list,
.experience-skills-list {
  list-style-type: none; /* Remove bullet points */
  padding-left: 0;
}

/* Skills list displayed inline with separators */
.skills-list li,
.experience-skills-list li {
  display: inline; /* Display skills inline */
  margin-right: 10px; /* Space between skills */
}

/* Adding a separator after each skill except the last one */
/* Adjusting the separator's appearance */
.skills-list li::after,
.experience-skills-list li::after {
  content: "•"; /* Changed to a bullet for a bit more visibility */
  margin-left: 5px;
  font-size: 1.2em; /* Make the dot larger than the surrounding text */
  vertical-align: middle; /* Align it with the middle of the text */
}

.skills-list li:last-child::after,
.experience-skills-list li:last-child::after {
  content: ""; /* No separator after the last skill */
}

/* Ensure contributions are still displayed with bullet points */
.experience ul {
  list-style-type: disc; /* Restore bullet points for contributions */
  padding-left: 20px; /* Indent contributions */
}

/* Adjusting general and specific spacings for readability */
section {
  margin-bottom: 20px; /* Better separation between sections */
}

p,
li {
  margin-bottom: 10px; /* Spacing for readability */
}

.experience article {
  margin-bottom: 30px; /* Space between each experience section */
}

.experience h3,
.experience-skills,
.experience p,
.experience ul {
  margin-top: 15px; /* Uniform space above elements within experiences */
}
